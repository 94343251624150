* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  background: #0c507d;
}
body.arthropod {
  font-family: 'IBMPlexSans', Arial, Helvetica, sans-serif;
  background: #fff;
}
header.uploaf {
  width: 100%;
  background: #116397;
  background-image: linear-gradient(to bottom, #0f5785, #1475b3);
  background-repeat: repeat-x;
  color: #fff;
  height: 150px;
  text-align: center;
  position: relative;
}
header.uploaf h1 {
  text-align: center;
  margin: 0;
  line-height: 150px;
  font-size: 50px;
}
main {
  background: #fff;
  font-size: 16px;
}
footer.uploaf {
  text-align: center;
  background: #0c507d;
  background-image: linear-gradient(to bottom, #0e5683, #0c507d);
  background-repeat: repeat-x;
  color: #fff;
  height: 100px;
  line-height: 100px;
}
footer.uploaf p {
  margin: 0;
}

.main-logo {
  width: 400px;
  height: 60px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

button {
  color: #fff;
  background-color: #1475b3;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
main.arthropod button:not(.uppy-u-reset, .uppy-DashboardContent-back, .uppy-DashboardContent-addMore) {
  background-color: rgb(71, 68, 67);
}
button:active {
  background-color: rgba(22, 22, 23, .8);
}
button#refresh_activity {
  background-color: #fff;
  border: 1px solid rgba(22, 22, 23, 1);
  color: rgba(22, 22, 23, 1);
  margin-left: 20px;
}
button#refresh_activity:active {
  background-color: rgba(22,22,22,0.1);
}
button:disabled,
body.arthropod button:disabled {
  background-color: rgba(22,22,23,0.3);
  cursor: default;
}
.upload-section {
  padding: 70px 30px 30px;
  margin:  0 auto;
  width: 800px;
}
.upload-btn {
  text-align: center;
}
#uppyModalOpener {
  font-size: 30px;
  padding: 10px 20px;
}
.files-delete-btn {
  display: none;
}
.files-delete-btn.show {
  display: block;
}

.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  border: 1px solid rgba(22, 22, 23, 1);
  background: #fff;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  box-shadow: 6px 6px 15px rgba(22, 22, 23, 0.5);
  min-width: 400px;
  .bold {
    font-weight: bold;
  }
}

.files-section {
  width: 60%;
  margin: 30px auto 0;
  border: 1px solid rgba(22, 22, 23, 1);
  display: none;
}
.files-section.show {
  display: block;
}
.files-section-items {
  max-height: 400px;
  overflow-y: scroll;
}
.files-section-header,
.files-section-item,
.download-files-header,
.download-files-item {
  display: flex;
  padding: 10px 0;
}
.files-section-header {
  font-weight: bold;
  border-bottom: 1px solid rgba(22, 22, 23, 1);
}
.files-section-item:nth-child(even),
.download-files-item:nth-child(even) {
  background: rgba(22,22,22,0.1);
}
.files-section-header > div:nth-child(1),
.files-section-item > div:nth-child(1) {
  max-width: 60px;
  text-align: center;
}
.files-section-header > div,
.files-section-item > div {
  flex-grow: 1;
  text-align: center;
}
.files-section-header > div:last-child,
.files-section-item > div:last-child {
  max-width: 120px;
}
.files-delete-btn {
  text-align: center;
}
.download-section {
  margin: 0 auto;
  width: 800px;
  padding: 80px 0 40px;
  min-height: 500px;
}
.download-files {
  width: 60%;
  border: 1px solid rgba(22, 22, 23, 1);
  margin: 0 auto 40px;
}
.download-files-header {
  font-weight: bold;
  border-bottom: 1px solid rgba(22, 22, 23, 1);
}
.download-files-header > div,
.download-files-item > div {
  width: 50%;
}
.download-files-header > div:nth-child(1),
.download-files-item > div:nth-child(1) {
  padding-left: 20px;
}
.download-files-item a {
  color: rgba(22, 22, 23, 1);
}
.download-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(22, 22, 23, 1);
  background: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 6px 6px 15px rgba(22, 22, 23, 0.5);
  display: none;
}
.download-loading {
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.download-loading div {
  display: inline-block;
  position: absolute;
  left: 8px;
  top: 24px;
  width: 16px;
  height: 32px;
  background: #1475b3;
  animation: download-all 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.download-loading div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.download-loading div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.download-loading div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes download-all {
  0% {
    top: 8px;
    height: 64px;
  }
  50% {
    top: 24px;
    height: 32px;
  }
}
.from-email,
.from-message,
.from-terms {
  border: 1px solid rgba(22, 22, 22, 1);
  background-color: rgba(22,22,22,0.1);
}
.from-email {
  padding: 5px;
}
.from-message,
.from-terms {
  padding: 15px;
  white-space: pre-wrap;
}
#expiry_msg {
  margin: 40px;
  font-weight: bold;
  text-align: center;
}
.terms-section {
  margin-top: 50px;
  text-align: center;
}
.cleanup-btn {
  text-align: center;
  margin:40px;
}
label {
  display: inline-block;
  margin-bottom: 6px;
  font-weight: bold;
}
label span {
  font-weight: normal;
}
input[type="text"] {
  width: 100%;
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(22, 22, 23, 1);
}
input:read-only {
  background-color: rgb(245, 245, 244);
  border-color: rgb(206, 203, 202);
}
textarea {
  width: 100%;
  height: 160px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(22, 22, 23, 1);
  resize: none;
}
.submit-btn {
  text-align: center;
}
.submit-btn button {
  font-size: 20px;
}

.error-msg {
  display: none;
  margin-top: 3px;
}
.sender-email.error input,
.recipient-email.error input,
.sender-message.error textarea,
.sender-tc.error textarea {
  border: 1px solid red;
}
.sender-email.error .error-msg,
.recipient-email.error .error-msg,
.sender-message.error .error-msg,
.sender-tc.error .error-msg {
  display: block;
  color: red;
}
.activity-log {
  border: 1px solid rgba(22, 22, 23, 1);
  padding: 0 20px 20px;
  /*display: none;*/
}
.activity-log.show {
  display: block;
}
.activity-header,
.activity-item {
  display: flex;
  border-bottom: 1px solid rgba(22,22,22,0.4);
}
.activity-item:last-child {
  border-bottom: 0;
}
.activity-header {
  font-weight: bold;
}
.activity-header > div,
.activity-item > div {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
}
.activity-header > div:nth-child(1),
.activity-item > div:nth-child(1) {
  width: 145px;
  min-width: 145px;
  max-width: 145px;
}
.activity-header > div:nth-child(2),
.activity-item > div:nth-child(2) {
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}
.download-error-message {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.download-error-close {
  text-align: center;
}
.confirm-delete-popup {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid rgba(22, 22, 23, 1);
  padding: 20px;
  text-align: center;
  box-shadow: 6px 6px 15px rgba(22, 22, 23, 0.5);
}

/* UPPY styles */

.uppy-size--md .uppy-Dashboard-Item {
  width: auto;
  max-width: 65px;
  height: auto;
}
.uppy-size--md .uppy-Dashboard-Item-preview {
  height: auto;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1475b3;
  margin: auto;
}
.uppy-Dashboard-Item-previewIconBg {
  height: 75%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
}
.uppy-Dashboard-Item-name {
  width: 65px;
  overflow: hidden;
  height: 15px;
}
